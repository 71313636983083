import React from 'react';


const IndexPage = () => (
    <div className="container-fluid p-0">
        <header className="major">
          <h2>
            Page not found
            <br />
            Not a valid URL
          </h2>
        </header>
    </div>
);

export default IndexPage;
